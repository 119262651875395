/* eslint-disable no-unused-expressions */
import * as constants from '../constants/authConstants';
import * as adminConstants from '../constants/adminConstants';
import { adminInstance } from '../axios';

export const login = (email, password, remember) => async (dispatch) => {
    dispatch({
        type: constants.ADMIN_LOGIN_REQUEST,
    });

    const { data } = await adminInstance.post('/login', {
        email,
        password,
        remember,
    });
    console.log('req.body............', data);
    const { code } = data;
    if (code === 200) {
        const { username, token, superadmin, roles, adminId } = data;

        const payload = {
            username,
            token,
            superadmin,
            adminId,
        };

        console.log(payload, JSON.stringify(payload));
        localStorage.setItem('admintoken', JSON.stringify(payload));
        localStorage.setItem('adminId', payload.adminId);

        dispatch({
            type: constants.ADMIN_LOGIN_SUCCESS,
            payload: {
                ...payload,
                roles,
            },
        });
    } else if (code === 400) {
        dispatch({
            type: constants.ADMIN_LOGIN_FAIL,
            payload: data,
        });
        dispatch({ type: '' });
    } else {
        dispatch({
            type: constants.ADMIN_LOGIN_FAIL,
            payload: data.msg,
        });
    }
};

export const verifyOtp = (email, otp) => async (dispatch) => {
    dispatch({
        type: constants.VERIFY_OTP_REQUEST,
    });

    const { data } = await adminInstance.post('/auth/verify', {
        email,
        otp,
    });
    const { code } = data;
    if (code === 200) {
        const { username, email } = data;
        const payload = {
            username,
            email,
        };
        dispatch({
            type: constants.VERIFY_OTP_SUCCESS,
            payload,
        });
        dispatch({
            type: constants.USER_VERIFIED,
        });
    } else if (code === 400) {
        dispatch({
            type: constants.VERIFY_OTP_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: constants.VERIFY_OTP_FAIL,
            payload: data.msg,
        });
    }
};

export const forgetPassword = (email) => async (dispatch) => {
    dispatch({
        type: constants.FORGET_PASSWORD_REQUEST,
    });

    const { data } = await adminInstance.post('/auth/forgetPassword', {
        email,
    });

    const { code } = data;
    if (code === 200) {
        const { username, email, msg } = data;
        const payload = {
            username,
            email,
            msg,
        };
        dispatch({
            type: constants.FORGET_PASSWORD_SUCCESS,
            payload,
        });
        dispatch({ type: '' });
    } else {
        dispatch({
            type: constants.FORGET_PASSWORD_FAIL,
            payload: data.msg,
        });
    }
};

export const getMyDetails = () => async (dispatch) => {
    dispatch({
        type: adminConstants.MY_DETAILS_REQUEST,
    });
    const { data } = await adminInstance.get('/getAdminDetails');
    // console.log('data',data.adminInfo.photo.)
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.MY_DETAILS_SUCCESS,
            payload: data.adminInfo,
        });
    } else {
        dispatch({
            type: adminConstants.MY_DETAILS_FAIL,
            payload: data.msg,
        });
    }
};

export const editMyDetails = (userData) => async (dispatch) => {
    dispatch({
        type: adminConstants.EDIT_DETAILS_REQUEST,
    });

    const { data } = await adminInstance.post('/editAdminDetails', userData);
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.EDIT_DETAILS_SUCCESS,
            payload: data.msg,
        });
        dispatch({ type: '' });
    } else {
        dispatch({
            type: adminConstants.EDIT_DETAILS_FAIL,
            payload: data.msg,
        });
    }
};

export const getCountData = () => async (dispatch) => {
    dispatch({
        type: adminConstants.GET_COUNTS_REQUEST,
    });

    const { data } = await adminInstance.get('/getCount');
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.GET_COUNTS_SUCCESS,
            payload: data.list,
        });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.GET_COUNTS_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.GET_COUNTS_FAIL,
            payload: data.msg,
        });
    }
};

export const addSubAdmin = (payload) => async (dispatch) => {
    dispatch({
        type: adminConstants.ADD_SUBADMIN_REQUEST,
    });

    const { data } = await adminInstance.post('/addAdmin', payload);
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.ADD_SUBADMIN_SUCCESS,
            payload: data.msg,
        });
        dispatch({ type: '' });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.ADD_SUBADMIN_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.ADD_SUBADMIN_FAIL,
            payload: data.msg,
        });
    }
};

export const getSubAdminList = (payload) => async (dispatch) => {
    dispatch({
        type: adminConstants.SUBADMIN_LIST_REQUEST,
    });

    const { data } = await adminInstance.post('/getAllAdmin', payload);
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.SUBADMIN_LIST_SUCCESS,
            payload: { list: data.list, total: data.total },
        });
    } else if (code === 210) {
        const { token, superadmin } = data;
        const payload = {
            token,
            superadmin,
        };
        localStorage.setItem('admintoken', JSON.stringify(payload));
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.SUBADMIN_LIST_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.SUBADMIN_LIST_FAIL,
            payload: data.msg,
        });
    }
};

/// newly created
export const blockAdmin = (email) => async (dispatch) => {
    dispatch({
        type: adminConstants.BLOCK_SUBADMIN_REQUEST,
    });

    const { data } = await adminInstance.post('/blockadmin', {
        email,
    });
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.BLOCK_SUBADMIN_SUCCESS,
            payload: data.msg,
        });
        dispatch({ type: '' });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.BLOCK_SUBADMIN_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.BLOCK_SUBADMIN_FAIL,
            payload: data.msg,
        });
    }
};

// export const getUsersList = (payload) => async (dispatch) => {
//   dispatch({
//     type: adminConstants.USER_LIST_REQUEST,
//   });
//   const { data } = await adminInstance.post("/getAllUsers", payload);
//   const { code } = data;
//   if (code === 200) {
//     // console.log('datadata----------------->', data.total.count);
//     dispatch({
//       type: adminConstants.USER_LIST_SUCCESS,
//       payload: { list: data.list, total: data.total.count },
//     });
//   } else if (code === 401) {
//     dispatch(notAuthorized());
//     dispatch({
//       type: adminConstants.USER_LIST_FAIL,
//       payload: data.msg,
//     });
//   } else {
//     dispatch({
//       type: adminConstants.USER_LIST_FAIL,
//       payload: data.msg,
//     });
//   }
// };

export const getUsersList = (id) => async (dispatch) => {
    dispatch({
        type: adminConstants.USER_LIST_REQUEST,
    });
    const { data } = await adminInstance.post('/getAllUsers', { id });
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.USER_LIST_SUCCESS,
            payload: { list: data.list, total: data.total.count },
        });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.USER_LIST_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.USER_LIST_FAIL,
            payload: data.msg,
        });
    }
};

export const getUsersDeletedList = (id) => async (dispatch) => {
    console.log('called');
    dispatch({
        type: adminConstants.DELETED_MEMBER_LIST_REQUEST,
    });
    const { data } = await adminInstance.post('/getAllDeletedUsers', { id });
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.DELETED_MEMBER_LIST_SUCCESS,
            payload: { list: data.list, total: data.total.count },
        });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.DELETED_MEMBER_LIST_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.DELETED_MEMBER_LIST_FAIL,
            payload: data.msg,
        });
    }
};

export const addGameType = (payload) => async (dispatch) => {
    dispatch({
        type: adminConstants.ADD_GAME_REQUEST,
    });

    const { data } = await adminInstance.post('/addGame', payload);
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.ADD_GAME_SUCCESS,
            payload: data.list,
        });
        dispatch({ type: '' });
    } else if (code === 400) {
        dispatch({});
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.ADD_GAME_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.ADD_GAME_FAIL,
            payload: data.msg,
        });
    }
};

export const deleteAdmin = (email) => async (dispatch) => {
    const id = email.id;
    console.log('email', email);
    dispatch({
        type: adminConstants.DELETE_SUBADMIN_REQUEST,
    });

    const { data } = await adminInstance.post('/deleteAdmin', {
        id,
    });

    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.DELETE_SUBADMIN_SUCCESS,
            payload: data.msg,
        });
        dispatch({ type: '' });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.DELETE_SUBADMIN_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.DELETE_SUBADMIN_FAIL,
            payload: data.msg,
        });
    }
};

export const deleteUser = (email) => async (dispatch) => {
    dispatch({
        type: adminConstants.DELETE_USER_REQUEST,
    });
    const { data } = await adminInstance.post('/deleteUser', {
        id: email,
    });
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.DELETE_USER_SUCCESS,
            payload: data.msg,
        });
        dispatch({ type: '' });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.DELETE_USER_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.DELETE_USER_FAIL,
            payload: data.msg,
        });
    }
};

//----------------------------------------------------------------------------------

export const blockUser = (email, isIp, msg) => async (dispatch) => {
    dispatch({
        type: adminConstants.BLOCK_USER_REQUEST,
    });

    const { data } = await adminInstance.post('/blockUser', {
        email,
        isIp,
        msg,
    });

    console.log('Response---', data);
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.BLOCK_USER_SUCCESS,
            payload: data.msg,
        });
        dispatch({ type: '' });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.BLOCK_USER_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.BLOCK_USER_FAIL,
            payload: data.msg,
        });
    }
};

//-----------------------------------------------------------------

export const adminforgetPassword = (email) => async (dispatch) => {
    dispatch({
        type: adminConstants.FORGET_PASSWORD_REQUEST,
    });

    const { data } = await adminInstance.post('/forgetPassword', {
        email,
    });

    const { code } = data;
    if (code === 200) {
        const { username, email, msg } = data;
        const payload = {
            username,
            email,
            msg,
        };

        dispatch({
            type: adminConstants.FORGET_PASSWORD_SUCCESS,
            payload,
        });
        dispatch({ type: '' });
    } else {
        dispatch({
            type: adminConstants.FORGET_PASSWORD_FAIL,
            payload: data.msg,
        });
    }
};

export const adminverifyOtp = (email, otp) => async (dispatch) => {
    dispatch({
        type: adminConstants.VERIFY_OTP_REQUEST,
    });

    const { data } = await adminInstance.post('/otpVerify', {
        email,
        otp,
    });
    const { code } = data;
    if (code === 200) {
        const { username, email } = data;
        const payload = {
            username,
            email,
        };
        dispatch({
            type: adminConstants.VERIFY_OTP_SUCCESS,
            payload,
        });
        dispatch({
            type: constants.ADMIN_VERIFIED,
        });
    } else if (code === 400) {
        dispatch({
            type: adminConstants.VERIFY_OTP_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.VERIFY_OTP_FAIL,
            payload: data.msg,
        });
    }
};

export const adminnewPassword = (payload) => async (dispatch) => {
    dispatch({
        type: adminConstants.NEW_PASSWORD_REQUEST,
    });

    const { data } = await adminInstance.post('/resetpassword', payload);
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.NEW_PASSWORD_SUCCESS,
            payload: data.msg,
        });
        dispatch({ type: '' });
    } else {
        dispatch({
            type: adminConstants.NEW_PASSWORD_FAIL,
            payload: data.msg,
        });
    }
};

const notAuthorized = () => {
    localStorage.removeItem('admintoken');
    window.location.href = '/admin/login';
};

export const editSubadmin = (admindetail) => async (dispatch) => {
    dispatch({
        type: adminConstants.EDIT_ADMIN_REQUEST,
    });
    const { data } = await adminInstance.post('/editAdmin', admindetail);
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.EDIT_ADMIN_SUCCESS,
            payload: data.msg,
        });
        dispatch({ type: '' });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.EDIT_ADMIN_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.EDIT_ADMIN_FAIL,
            payload: data.msg,
        });
    }
};

export const adminchangePassword = (password) => async (dispatch) => {
    dispatch({
        type: adminConstants.CHANGE_PASSWORD_REQUEST,
    });
    console.log('Password in action ===> ', password);
    const { data } = await adminInstance.post('/changePassword', {
        password,
    });

    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.CHANGE_PASSWORD_SUCCESS,
            payload: data.msg,
        });
        dispatch({ type: '' });
    } else {
        dispatch({
            type: adminConstants.CHANGE_PASSWORD_FAIL,
            payload: data.msg,
        });
        dispatch({ type: '' });
    }
};

export const getSubadmin = (admindetail) => async (dispatch) => {
    dispatch({
        type: adminConstants.GET_ADMIN_REQUEST,
    });
    console.log('User  =>', admindetail);
    const { data } = await adminInstance.post('/getAdmin', admindetail);
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.GET_ADMIN_SUCCESS,
            payload: data,
        });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.GET_ADMIN_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.GET_ADMIN_FAIL,
            payload: data.msg,
        });
    }
};

// export const getAllRecentTransactionList =
//   (page, pagination, username) => async (dispatch) => {
//     dispatch({
//       type: adminConstants.RECENT_TRANSACTION_LIST_REQUEST,
//     });

//     const { data } = await adminInstance.post("/getAllcountry", {
//       page,
//       pagination,
//       username,
//     });
//     const { code } = data;
//     if (code === 200) {
//       dispatch({
//         type: adminConstants.RECENT_TRANSACTION_LIST_SUCCESS,
//         payload: { list: data.list, totalCount: data.totalCount },
//       });
//       console.log(data.list);
//     } else if (code === 401) {
//       dispatch(notAuthorized());
//       dispatch({
//         type: adminConstants.RECENT_TRANSACTION_LIST_FAIL,
//         payload: data.msg,
//       });
//     } else {
//       dispatch({
//         type: adminConstants.RECENT_TRANSACTION_LIST_FAIL,
//         payload: data.msg,
//       });
//     }
//   };

export const getAllRecentTransactionList = () => async (dispatch) => {
    dispatch({
        type: adminConstants.RECENT_TRANSACTION_LIST_REQUEST,
    });

    const { data } = await adminInstance.post('/getAllcountry');
    const { code } = data;
    console.log('hhhhhhh', data);
    if (code === 200) {
        dispatch({
            type: adminConstants.RECENT_TRANSACTION_LIST_SUCCESS,
            payload: { list: data.list, totalCount: data.totalCount },
        });
        console.log(data.list);
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.RECENT_TRANSACTION_LIST_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.RECENT_TRANSACTION_LIST_FAIL,
            payload: data.msg,
        });
    }
};

export const getPaymentTransction =
    (page, pagination, username) => async (dispatch) => {
        dispatch({
            type: adminConstants.PAYMENT_TRANSACTION_LIST_REQUEST,
        });

        const { data } = await adminInstance.post('/getPaymentTransaction', {
            page,
            pagination,
            username,
        });
        const { code } = data;
        if (code === 200) {
            dispatch({
                type: adminConstants.PAYMENT_TRANSACTION_LIST_SUCCESS,
                payload: { list: data.list, totalCount: data.totalCount },
            });
            console.log(data.list);
        } else if (code === 401) {
            dispatch(notAuthorized());
            dispatch({
                type: adminConstants.PAYMENT_TRANSACTION_LIST_FAIL,
                payload: data.msg,
            });
        } else {
            dispatch({
                type: adminConstants.PAYMENT_TRANSACTION_LIST_FAIL,
                payload: data.msg,
            });
        }
    };

export const getKyc = (page, pagination, username) => async (dispatch) => {
    dispatch({
        type: adminConstants.GET_KYC_REQUEST,
    });

    const { data } = await adminInstance.post('/kycList', {
        page,
        pagination,
        username,
    });
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.GET_KYC_SUCCESS,
            payload: { list: data.list, totalCount: data.total },
        });
        console.log(data.list);
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.GET_KYC_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.GET_KYC_FAIL,
            payload: data.msg,
        });
    }
};

export const getKycVerified =
    (page, pagination, username) => async (dispatch) => {
        dispatch({
            type: adminConstants.GET_KYC_VERIFIED_REQUEST,
        });

        const { data } = await adminInstance.post('/kycVerifiedList', {
            page,
            pagination,
            username,
        });
        const { code } = data;
        if (code === 200) {
            dispatch({
                type: adminConstants.GET_KYC_VERIFIED_SUCCESS,
                payload: { list: data.list, totalCount: data.total },
            });
            console.log(data.list);
        } else if (code === 401) {
            dispatch(notAuthorized());
            dispatch({
                type: adminConstants.GET_KYC_VERIFIED_FAIL,
                payload: data.msg,
            });
        } else {
            dispatch({
                type: adminConstants.GET_KYC_VERIFIED_FAIL,
                payload: data.msg,
            });
        }
    };

export const getKycRejected =
    (page, pagination, username) => async (dispatch) => {
        dispatch({
            type: adminConstants.GET_KYC_REJECTED_REQUEST,
        });

        const { data } = await adminInstance.post('/kycRejectedList', {
            page,
            pagination,
            username,
        });
        const { code } = data;
        if (code === 200) {
            dispatch({
                type: adminConstants.GET_KYC_REJECTED_SUCCESS,
                payload: { list: data.list, totalCount: data.total },
            });
            console.log(data.list);
        } else if (code === 401) {
            dispatch(notAuthorized());
            dispatch({
                type: adminConstants.GET_KYC_REJECTED_FAIL,
                payload: data.msg,
            });
        } else {
            dispatch({
                type: adminConstants.GET_KYC_REJECTED_FAIL,
                payload: data.msg,
            });
        }
    };

export const getWithdraw = (page, pagination) => async (dispatch) => {
    dispatch({
        type: adminConstants.GET_WITHDRAW_REQUEST,
    });

    const { data } = await adminInstance.post('/withdrawRequests', {
        page: page,
        pagination: pagination,
    });
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.GET_WITHDRAW_SUCCESS,
            payload: { list: data.list, totalCount: data.total },
        });
        console.log(data.list);
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.GET_WITHDRAW_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.GET_WITHDRAW_FAIL,
            payload: data.msg,
        });
    }
};

// export const getmatches = (page, pagination, dataa) => async (dispatch) => {
//   dispatch({
//     type: adminConstants.GET_MATCHING_REQUEST,
//   });
//   const { data } = await adminInstance.post("/eventlist", {
//     page,
//     pagination,
//     dataa,
//   });
//   const { code } = data;
//   // console.log('datadata', data.totalCount);
//   if (code === 200) {
//     dispatch({
//       type: adminConstants.GET_MATCHING_SUCCESS,
//       payload: { list: data.list, totalCount: data.totalCount },
//     });
//   } else if (code === 401) {
//     dispatch(notAuthorized());
//     dispatch({
//       type: adminConstants.GET_MATCHING_FAIL,
//       payload: data.msg,
//     });
//   } else {
//     dispatch({
//       type: adminConstants.GET_MATCHING_FAIL,
//       payload: data.msg,
//     });
//   }
// };

export const getmatches = (id) => async (dispatch) => {
    dispatch({
        type: adminConstants.GET_MATCHING_REQUEST,
    });
    const { data } = await adminInstance.post('/eventlist', {
        id,
    });
    const { code } = data;
    // console.log('datadata', data.totalCount);
    if (code === 200) {
        dispatch({
            type: adminConstants.GET_MATCHING_SUCCESS,
            payload: { list: data.list, totalCount: data.totalCount },
        });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.GET_MATCHING_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.GET_MATCHING_FAIL,
            payload: data.msg,
        });
    }
};

export const getmycalendar = (page, pagination, dataa) => async (dispatch) => {
    dispatch({
        type: adminConstants.UNREAD_ADMIN_NOTIFY_REQUEST,
    });
    const { data } = await adminInstance.post('/mycaledarlist', {
        page,
        pagination,
        dataa,
    });

    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.UNREAD_ADMIN_NOTIFY_SUCCESS,
            payload: { list: data.UserData, totalCount: data.total },
        });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.UNREAD_ADMIN_NOTIFY_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.UNREAD_ADMIN_NOTIFY_FAIL,
            payload: data.msg,
        });
    }
};

export const getUnReadNotificationAdmin =
    (page, pagination) => async (dispatch) => {
        dispatch({
            type: adminConstants.UNREAD_ADMIN_NOTIFY_REQUEST,
        });
        const { data } = await adminInstance.post('/getUnreadNotification', {
            page,
            pagination,
        });
        const { code } = data;
        if (code === 200) {
            dispatch({
                type: adminConstants.UNREAD_ADMIN_NOTIFY_SUCCESS,
                payload: data,
            });
        } else if (code === 401) {
            dispatch(notAuthorized());
            dispatch({
                type: adminConstants.UNREAD_ADMIN_NOTIFY_FAIL,
                payload: data.msg,
            });
        } else {
            dispatch({
                type: adminConstants.UNREAD_ADMIN_NOTIFY_FAIL,
                payload: data.msg,
            });
        }
    };

export const getDisputeMatches = (page, pagination) => async (dispatch) => {
    dispatch({
        type: adminConstants.GET_DISPUTE_MATCHES_REQUEST,
    });
    const { data } = await adminInstance.post('/disputeMatchList', {
        page,
        pagination,
    });
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.GET_DISPUTE_MATCHES_SUCCESS,
            payload: { list: data.list, totalCount: data.total },
        });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.GET_DISPUTE_MATCHES_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.GET_DISPUTE_MATCHES_FAIL,
            payload: data.msg,
        });
    }
};

export const getSupportUser = () => async (dispatch) => {
    dispatch({
        type: adminConstants.SUPPORT_USER_REQUEST,
    });
    const { data } = await adminInstance.get('/fetchSupportUser');
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.SUPPORT_USER_SUCCESS,
            payload: data.users,
        });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.SUPPORT_USER_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.SUPPORT_USER_FAIL,
            payload: data.msg,
        });
    }
};

export const getAllBlastList = (id) => async (dispatch) => {
    dispatch({
        type: adminConstants.BLAST_LIST_REQUEST,
    });
    console.log('id', id);
    const { data } = await adminInstance.post('/getAllBlast', { id });
    console.log('data', data);
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.BLAST_LIST_SUCCESS,
            payload: { list: data.blastList, totalCount: data.totalCount },
        });
        console.log('fgfdgdfgdfgdfg', data.blastList);
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.BLAST_LIST_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.BLAST_LIST_FAIL,
            payload: data.msg,
        });
    }
};

export const getAllPolicyList = (id) => async (dispatch) => {
    dispatch({
        type: adminConstants.POLICY_LIST_REQUEST,
    });
    console.log('id', id);
    const { data } = await adminInstance.post('/getAllPolicy', { id });
    console.log('data', data);
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.POLICY_LIST_SUCCESS,
            payload: { list: data.policyList, totalCount: data.totalCount },
        });
        console.log('yyyyyyyyyyy', data.policyList);
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.POLICY_LIST_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.POLICY_LIST_FAIL,
            payload: data.msg,
        });
    }
};

// export const getBlastDataById = (id) => async (dispatch) => {
//   dispatch({
//     type: adminConstants.BLAST_LIST_REQUEST,
//   });
//   console.log("id", id);
//   const { data } = await adminInstance.post("/getUserById", { id });
//   console.log("data", data);
//   const { code } = data;
//   if (code === 200) {
//     dispatch({
//       type: adminConstants.BLAST_LIST_BY_ID_SUCCESS,
//       payload: { list: data.blastList, totalCount: data.totalCount },
//     });
//     console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh", data.blastList);
//   } else if (code === 401) {
//     dispatch(notAuthorized());
//     dispatch({
//       type: adminConstants.BLAST_LIST_BY_ID_FAIL,
//       payload: data.msg,
//     });
//   } else {
//     dispatch({
//       type: adminConstants.BLAST_LIST_BY_ID_FAIL,
//       payload: data.msg,
//     });
//   }
// };

export const getExpiredMembershipList = () => async (dispatch) => {
    dispatch({
        type: adminConstants.EXPIRED_MEMBERSHIP_LIST_REQUEST,
    });
    const { data } = await adminInstance.post('/getAllExpiredMembership');
    const { code } = data;
    if (code === 200) {
        console.log('datadata----------------->', data);
        dispatch({
            type: adminConstants.EXPIRED_MEMBERSHIP_LIST_SUCCESS,
            payload: { list: data.list, total: data.total.count },
        });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.EXPIRED_MEMBERSHIP_LIST_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.EXPIRED_MEMBERSHIP_LIST_FAIL,
            payload: data.msg,
        });
    }
};

export const getUsersListById = (id) => async (dispatch) => {
    dispatch({
        type: adminConstants.USER_LIST_BY_ID_REQUEST,
    });
    const { data } = await adminInstance.post('/getUserById', { id });
    const { code } = data;
    if (code === 200) {
        console.log('datadata----------------->', data);
        dispatch({
            type: adminConstants.USER_LIST_BY_ID_SUCCESS,
            payload: { list: data.list, total: data.total.count },
        });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.USER_LIST_BY_ID_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.USER_LIST_BY_ID_FAIL,
            payload: data.msg,
        });
    }
};

export const FollowList = () => async (dispatch) => {
    dispatch({
        type: adminConstants.USER_LIST_REQUEST,
    });
    const { data } = await adminInstance.post('/getAllFollow');
    const { code } = data;
    if (code === 200) {
        // console.log('datadata----------------->', data.total.count);
        dispatch({
            type: adminConstants.FOLLOW_LIST_SUCCESS,
            payload: { list: data.list, total: data.total.count },
        });
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.FOLLOW_LIST_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.FOLLOW_LIST_FAIL,
            payload: data.msg,
        });
    }
};

export const getBlastListById = (id) => async (dispatch) => {
    dispatch({
        type: adminConstants.BLAST_LIST_BY_ID_REQUEST,
    });
    const { data } = await adminInstance.post('/getBlastById', { id });
    const { code } = data;
    if (code === 200) {
        console.log('datadata blasttt----------------->', data);
        dispatch({
            type: adminConstants.BLAST_LIST_BY_ID_SUCCESS,
            payload: { list: data.list, total: data.total },
        });
        console.log('hiiiiiiiiii', data.total);
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.BLAST_LIST_BY_ID_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.BLAST_LIST_BY_ID_FAIL,
            payload: data.msg,
        });
    }
};

//---------------------Mail data--------------------------------------------

// export const sendMail = (payload) => async (dispatch) => {
//   console.log('mail data ---',payload);
//   dispatch({
//     type: adminConstants.MAIL_REQUEST,
//   });
//   const { data } = await adminInstance.post('/sendMail', {payload});
//   console.log(data)
//   const { code } = data;
//   if (code === 200) {
//     console.log('response msg------',data.msg);
//     dispatch({
//       type: adminConstants.MAIL_SUCCESS,
//       payload: data.msg
//     });

//   }
//     else if (code === 401) {
//         dispatch(notAuthorized())
//         dispatch({
//           type: adminConstants.MAIL_FAIL,
//           payload: data.msg
//         })
//       } else {
//         dispatch({
//           type: adminConstants.MAIL_FAIL,
//           payload: data.msg
//         })
//       }
// };

export const getAllAdvertiseList = () => async (dispatch) => {
    dispatch({
        type: adminConstants.ADVERTISE_LIST_REQUEST,
    });
    //console.log("id", id);
    const { data } = await adminInstance.post('/getAllAdvertise');
    console.log('data', data);
    const { code } = data;
    if (code === 200) {
        dispatch({
            type: adminConstants.ADVERTISE_LIST_SUCCESS,
            payload: { list: data.advertiseList, totalCount: data.totalCount },
        });
        console.log('fgfdgdfgdfgdfg', data.advertiseList);
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.ADVERTISE_LIST_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.ADVERTISE_LIST_FAIL,
            payload: data.msg,
        });
    }
};

export const getAdvertiseListById = (id) => async (dispatch) => {
    dispatch({
        type: adminConstants.ADVERTISE_LIST_BY_ID_REQUEST,
    });
    const { data } = await adminInstance.post('/getAdvertiseById', { id });
    const { code } = data;
    if (code === 200) {
        console.log('datadata blasttt----------------->', data);
        dispatch({
            type: adminConstants.ADVERTISE_LIST_BY_ID_SUCCESS,
            payload: { list: data.list, total: data.total },
        });
        console.log('hiiiiiiiiii', data.total);
    } else if (code === 401) {
        dispatch(notAuthorized());
        dispatch({
            type: adminConstants.ADVERTISE_LIST_BY_ID_FAIL,
            payload: data.msg,
        });
    } else {
        dispatch({
            type: adminConstants.ADVERTISE_LIST_BY_ID_FAIL,
            payload: data.msg,
        });
    }
};
