import React from 'react';
import PropTypes from 'prop-types';
import Header from '../adminheader/header';
// import Footer from '../../footer/footer'

const Layout = ({ children }) => (
    <div className="wrapper">
        <Header />
        <div className="main_content">
            {children}
        </div>
        {/* <Footer /> */}

    </div>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired
}
export default Layout;