import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import pic from '../../assets/404.png';
import './404.css';

const Page404 = () => {
  const { t } = useTranslation();
  return (
    <div className="page-404">
      <div className="error-content">
        <img src={pic} alt="404" />
        <Link to="/">{t('404.goHome')}</Link>
      </div>
    </div>
  )
}
export default Page404;