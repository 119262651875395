import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const AdminMenu = ({ menutoggle }) => {
  const [roles, setRoles] = useState({});
  const adminProfile = useSelector((state) => state.adminProfile);
  let adminRoles = localStorage.getItem("adminRoles");
  adminRoles = adminRoles ? (adminRoles === "superadmin" ? adminRoles : JSON.parse(adminRoles)) : localStorage.setItem('adminRoles', "superadmin");
  console.log("adminRoles", adminRoles);

  const { userData } = adminProfile;
  useEffect(() => {
    if (userData) {
    }
  }, [userData]);

  const handleLogout = () => {
    localStorage.removeItem("admintoken");
    window.location.href = "/admin/login";
  };
  return (
    <div className={`admin-menu ${menutoggle ? `menu-hide` : ``}`}>
      <div className="menu-logo">
        <h2>OTR Admin</h2>
      </div>

      <div className="admin-menu-box">
        <div className="menu-list">
          <ul>
            {/* <h5>General</h5> */}

            <NavLink to="/admin/dashboard">
              <i className="fa fa-dashboard"></i> DASHBOARD
            </NavLink>
            <NavLink
              to="/admin/country"
              hidden={
                adminRoles === "superadmin"
                  ? false
                  : !adminRoles.countryManagement
              }
            >
              <i className="fa fa-sort"></i> COUNTRY LIST
            </NavLink>
            <NavLink
              to="/admin/mail"
              hidden={
                adminRoles === "superadmin" ? false : !adminRoles.mailManagement
              }
            >
              <i className="fa fa-envelope-o" aria-hidden="true"></i> SEND MAIL
            </NavLink>
            <NavLink
              to="/admin/user"
              hidden={
                adminRoles === "superadmin" ? false : !adminRoles.userManagement
              }
            >
              <i className="fa fa-user"></i> MEMBERS
            </NavLink>
            <NavLink
              to="/admin/event"
              hidden={
                adminRoles === "superadmin"
                  ? false
                  : !adminRoles.eventManagement
              }
            >
              <i className="fa fa-laptop"></i> EVENTS
            </NavLink>
            <NavLink
              to="/admin/follow"
              hidden={
                adminRoles === "superadmin"
                  ? false
                  : !adminRoles.followManagement
              }
            >
              <i className="fa fa-laptop"></i> FOLLOW (INVITE#)
            </NavLink>
            <NavLink
              to="/admin/blast"
              hidden={adminRoles === "superadmin" ? false : true}
            >
              <i className="fa fa-envelope-o" aria-hidden="true"></i> BLASTS
            </NavLink>
            <NavLink
              to="/admin/advertise"
              hidden={adminRoles === "superadmin" ? false : true}
            >
              <i className="fa fa-envelope-o" aria-hidden="true"></i> ADVERTISE
            </NavLink>
            <NavLink
              to="/admin/consent"
              hidden={adminRoles === "superadmin" ? false : true}
            >
              <i className="fa fa-envelope-o" aria-hidden="true"></i> CONSENT
              POLICIES
            </NavLink>
            <NavLink
              to="/admin/add-policy"
              hidden={adminRoles === "superadmin" ? false : true}
            >
              <i className="fa fa-envelope-o" aria-hidden="true"></i> ADD
              POLICIES
            </NavLink>
            <NavLink to="/admin/deletedRequest">
              <i className="fa fa-file"></i>DELETE REQUESTS
            </NavLink>
            {/* <NavLink to="/admin/cancelMembership">
              <i className="fa fa-file"></i>CANCEL MEMBERSHIPS
            </NavLink> */}
            <NavLink
              to="/admin/expired-membership"
              hidden={
                adminRoles === "superadmin"
                  ? false
                  : !adminRoles.expiredManagement
              }
            >
              <i className="fa fa-user"></i> EXPIRED MEMBERSHIPS
            </NavLink>

            <NavLink
              to="/admin/subadmin"
              hidden={
                adminRoles === "superadmin" ? false : !adminRoles.subManagement
              }
            >
              <i className="fa fa-users"></i> SUB-ADMIN MANAGEMENT
            </NavLink>

            <NavLink
              to="/admin/super-user"
              hidden={
                adminRoles === "superadmin" ? false : !adminRoles.subManagement
              }
            >
              <i className="fa fa-users"></i> SUPER USER
            </NavLink>

            <NavLink
              to="/admin/founding-user"
              hidden={
                adminRoles === "superadmin" ? false : !adminRoles.subManagement
              }
            >
              <i className="fa fa-users"></i> FOUNDING MEMBERS
            </NavLink>

            <NavLink
              to="/admin/promotion-user"
              hidden={
                adminRoles === "superadmin" ? false : !adminRoles.subManagement
              }
            >
              <i className="fa fa-users"></i> PROMO OFFERS
            </NavLink>

            {/* <NavLink to='/admin/commission'>
              <i className='fa fa-money'></i> Commission
            </NavLink> */}
            {/* <NavLink
            <NavLink
              to="/admin/follow"
              hidden={
                adminRoles === "superadmin"
                  ? false
                  : !adminRoles.followManagement
              }
            >
              <i className="fa fa-money"></i> Follow
            </NavLink>
            <NavLink
              to="/admin/my-calendar"
              hidden={
                adminRoles === "superadmin"
                  ? false
                  : !adminRoles.calenderManagement
              }
            >
              <i className="fa fa-comment"></i>My Calendar
            </NavLink> */}

            {/* <NavLink to="/admin/login" onClick={handleLogout}>
              <i className="fa fa-power-off"></i> Logout
            </NavLink> */}
            <NavLink
              to="/admin/discount"
              hidden={adminRoles === "superadmin" ? false : true}
            >
              <i className="fa fa-envelope-o" aria-hidden="true"></i> PROMO CODE
            </NavLink>
          </ul>
        </div>
      </div>
    </div>
  );
};

AdminMenu.propTypes = {
  menutoggle: PropTypes.bool,
};
export default AdminMenu;
