import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Form, Dropdown } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getUsersDeletedList, blockUser } from "../../../actions/adminAction";
import AdminWrapper from "../adminwrapper/wrapper";
import UserPopup from "../popup/user";
import DeletePopup from "../popup/delete";
import Loader from "../../loader/loader";
import UserTable from "./usertable";
import { useTable } from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import dots from "../../../assets/dots.png";
import UserDeletedTable from "./userDeletedTable";

const AdminDeletedUser = ({getDeletedRequest}) => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(null);
  const [userData, setUserData] = useState(null);

  const [keyword, setKeyword] = useState("");

  const [page, setPage] = useState(1);
  const [pagination] = useState(10);
  const [listcopy, setListCopy] = useState([]);
  const dispatch = useDispatch();
  const userListData = useSelector((state) => state.memberDeleted);
  const { error, memberDeleted, loading } = userListData;

  const userDeleteData = useSelector((state) => state.deleteUser);

  const { deleteUserStatus } = userDeleteData;

  const userBlockData = useSelector((state) => state.blockUser);
  const { blockUserStatus } = userBlockData;

  const handleModal = (e, mail) => {
    setShow(e);
    setEmail(mail);
  };

  useEffect(() => {
    if (error) toast.error(error, { id: "A" });
    const search = window.location.href;
    const id = search.split("=")[1];
    dispatch(getUsersDeletedList(id));
    getDeletedRequest();
    setShow(true);
  }, [dispatch, error, userDeleteData, userBlockData]);

  useEffect(() => {
    const arr = [];
    memberDeleted &&
      memberDeleted.list.forEach((el) => {
        let autoRenew = "";
        let sendReminder = "";
        let memberId = "";
        let membership_type = "";

        if (el)
          if (el.auto_renew === 1) {
            autoRenew = "Yes";
          } else {
            autoRenew = "No";
          }
        if (el.send_reminder === 1) {
          sendReminder = "Yes";
        } else {
          sendReminder = "No";
        }
        if (el.membership_id === "1") {
          const member = "A";
          memberId = member + el.id;
          membership_type = "Author Premium";
        }
        if (el.membership_id === "2") {
          const member = "R";
          memberId = member + el.id;
          membership_type = "Reader";
        }
        if (el.membership_id === "3") {
          const member = "L";
          memberId = member + el.id;
          membership_type = "Blogger";
        }
        if (el.membership_id === "4") {
          const member = "V";
          memberId = member + el.id;
          membership_type = "Vendor";
        }
        if (el.membership_id === "5") {
          const member = "PR";
          memberId = member + el.id;
          membership_type = "PR Firm";
        }
        if (el.membership_id === "6") {
          const member = "B";
          memberId = member + el.id;
          membership_type = "Blogger premium";
        }
        if (el.membership_id === "8") {
          const member = "U";
          memberId = member + el.id;
          membership_type = "Author";
        }

        arr.push({
          ...el,
          auto_renew: autoRenew,
          send_reminder: sendReminder,
          member_id: memberId,
          membership: membership_type,
          date_started: moment(el.created_date).format("MMM-DD-YYYY"),
          member_expiry_date: moment(el.member_expiry_date).format(
            "MMM-DD-YYYY"
          ),
        });
      });

    console.log("listCopy", arr);
    setListCopy(arr);
  }, [userListData]);

  useEffect(() => {
    if (deleteUserStatus) {
      toast.success(deleteUserStatus, { id: "A" });
    }
    if (userDeleteData.error) toast(userDeleteData.error, { id: "A" });
  }, [dispatch, deleteUserStatus, userDeleteData]);

  useEffect(() => {
    if (blockUserStatus) {
      toast.success(blockUserStatus, { id: "A" });
    }
    if (userBlockData.error) toast(userBlockData.error, { id: "A" });
  }, [dispatch, blockUserStatus, userBlockData]);

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },

    {
      Header: "Name",
      accessor: "display_name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Member ID",
      accessor: "member_id",
    },
    {
      Header: "Type of Membership",
      accessor: "membership",
    },
    {
      Header: "Date Started",
      accessor: "date_started",
    },
    {
      Header: "Updated Date",
      accessor: "updated_date",
    },

    {
      Header: "Action",
      accessor: "",
      Cell: (cell) => {
        return (
          <div className="action-dropdown action-buttons">
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                <img src={dots} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <Button
                    onClick={() => handleModal("delete", cell.row.values.id)}
                    className="delete-btn"
                  >
                    Delete
                  </Button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="admin-user">
        <div className="admin-transaction">
          <div className="container">
            <h2 className="admin-title">Delete Request</h2>
            <div className="admin-tab">
              <div className="user-search-box">
                <div className="user-search-section">
                  <div className="user-search"></div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {loading ? (
                      <Loader />
                    ) : (
                      memberDeleted && (
                        <UserDeletedTable
                          columns={columns}
                          data={listcopy && listcopy}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="login-popup"
        show={show === "delete"}
        onHide={() => handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeletePopup handleModal={handleModal} mail={email} type="user" />
        </Modal.Body>
      </Modal>

      <Modal
        className="login-popup"
        show={show === "user"}
        onHide={() => handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserPopup forwarded={"userlisting"} user={userData} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminDeletedUser;
